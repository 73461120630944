import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, Col, InputGroup, Input, InputGroupAddon, InputGroupText, Row, FormText, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { LEMD } from '../../config/globals';


const validate = values => {
    const errors = {};
    if (!values.username) {
        errors.username = "Please enter the username";
    }
    if (!values.password) {
        errors.password = "Please enter the Password";
    }
    return errors;
}

const renderField = ({
    input,
    label,
    type,
    icon,
    meta: { touched, error, warning }
}) => (
    <FormGroup className="mb-3">
        <InputGroup>
            <InputGroupAddon addonType="prepend">
                <InputGroupText>
                    <i style={{ color: "#000" }} className={icon}></i>
                </InputGroupText>
            </InputGroupAddon>
            <Input {...input} type={type} placeholder={label} autoComplete="off" />
        </InputGroup>
        {touched && ((error && <FormText color="danger">{error}</FormText>))}
    </FormGroup>
)

let LoginForm = props => {
    const { handleSubmit, submitting, csrf_token } = props
    //console.log("Submitting", submitting);
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                setIsloading(false);
            }, 5000);
        }
    }, [isLoading]);

    return (
        <form autoComplete="off" onSubmit={LEMD < Date.now() ? () => alert("Software License Expired") : handleSubmit}>
            <h1>Login</h1>
            <p className="text-muted">Sign In to your account</p>
            <Field component={renderField} icon="fa fa-user" type="text" label="Username" name="username" />
            <Field component={renderField} icon="fa fa-key" type="password" label="Password" name="password" />
            <input type="hidden" name="_csrf" value={csrf_token} />
            <Row>
                <Col xs="6">
                    <Button onClick={() => setIsloading(true)} disabled={submitting || LEMD < Date.now()} type="submit">{isLoading ? <i className='fa fa-spin fa-circle-o-notch'></i> : ""} Login</Button>
                </Col>
                <Col xs="6" className="text-right">
                    {/* <Button color="link" className="px-0">Forgot password?</Button> */}
                </Col>
            </Row>
        </form>
    );
}


export default reduxForm({
    form: 'login',
    validate
})(LoginForm);