import module_description from "./reducers/modules";
import field_list from "./reducers/fields";
import crm_records from "./reducers/records";
import crm_record from "./reducers/crm_record";

export default {
    module_description,
    field_list,
    crm_records,
    crm_record
}