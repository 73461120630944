import { CONNECTCO_CONFIG_LOADED } from '../config';

export default function (state = null, action) {
    switch (action.type) {
        case CONNECTCO_CONFIG_LOADED:
            return action.payload;
        default:
            return state;
    }
}
