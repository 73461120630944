import { SALES_ORDERS_LOADED } from '../config';

export default function (state = null, action) {
    switch (action.type) {
        case SALES_ORDERS_LOADED:
            return action.payload;
        default:
            return state;
    }
}
