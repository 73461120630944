import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import logo from '../theme/logo.png';

import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarHeader,
  AppSidebarNav,
  AppAside
} from '@coreui/react';

import Footer from './Footer';
import Header from './Header';
//import ActivityNotifications from './views/Reports/ActivityMonitor/notification';
import routes from '../config/routes';
import navigation from '../config/navigation';
import Reminders from './views/FollowUps/reminders';

class Home extends Component {
  render() {
    const menu = navigation.getItems();

    return (
      <div className="app">
        <AppHeader fixed>
          <Header />
        </AppHeader>
        <div className="app-body">
          <AppSidebar className="bg-primary pb-3" fixed display="lg">
            <AppSidebarHeader />
            <div className="py-5 text-center">
              <div className="bg-white p-3 rounded" style={{ display: "inline-block" }}>
                <img alt="Logo" style={{ height: 50 }} src={logo} />
              </div>
            </div>
            <AppSidebarNav navConfig={menu} {...this.props} />
          </AppSidebar>
          <main className="main">
            <Container fluid className="px-0">
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                    <route.component {...props} />
                  )} />)
                    : (null);
                },
                )}
                <Redirect from="/" to={menu.items[0].url || menu.items[0].children[0].url} />
              </Switch>
            </Container>
          </main>
          <AppAside fixed>
            <Reminders />
          </AppAside>
        </div>
        <AppFooter>
          <Footer />
        </AppFooter>
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return {
    user
  };
}

export default connect(mapStateToProps, null)(Home);
