import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, CardBody, CardGroup, Col, Container, Row } from 'reactstrap';

import LoginForm from './forms/LoginForm';
import { login, verifyOtp, resendOtp } from '../actions/index';
import logo from '../theme/logo.png';
import text from '../theme/texts.json';
import OtpForm from './forms/OtpForm';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOtp: false
    }
  }

  render() {
    const { login, verifyOtp, resendOtp } = this.props;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4 text-white bg-primary">
                  <CardBody>
                    {this.state.showOtp ? <OtpForm resendOtp={() => resendOtp((showLogin) => {
                      if (showLogin) {
                        this.setState({ showOtp: false })
                      }
                    })} handleSubmit={(data) => verifyOtp(data, (showLogin) => {
                      if (showLogin) {
                        this.setState({ showOtp: false })
                      }
                    })} /> : <LoginForm csrf_token={this.props.csrf_token} onSubmit={(data) => login(data, (showOtp) => {
                      this.setState({ showOtp, data: data })
                    })} />}

                  </CardBody>
                </Card>
                <Card className="py-4 d-md-down-none bg-white" style={{ width: 44 + '%' }}>
                  <CardBody className="text-center">
                    <div>
                      <div style={{ display: "inline-block" }} className='p-3 px-5 rounded mb-3 bg-white'>
                        <img src={logo} alt={"SimSyn"} className="mb-2" style={{ width: 100 }} />
                      </div>
                      <h4>{text.title}</h4>
                      <p style={{ fontSize: "0.8rem" }}>{text.description}</p>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    login,
    verifyOtp,
    resendOtp
  }, dispatch);
}


function mapStateToProps({ csrf_token }) {
  return {
    csrf_token
  };
}

export default (connect(mapStateToProps, mapDispatchToProps)(Login));