import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_CONFIG_LOADED, GROUP_CONFIG_LOADED, PROFILE_UPDATED, OUTBOUND_ID_CHANGED, RING_MY_MOBILE_CHANGED, TOKEN_REFRESHED } from '../actions/config';
import Cookies from 'js-cookie';

export default function (state = JSON.parse(Cookies.get('lbcc_user') || "null"), action) {
    switch (action.type) {
        case USER_LOGGED_IN:
            Cookies.set('lbcc_user', JSON.stringify(action.payload), { secure: true, sameSite: 'none' });
            return action.payload;
        case USER_LOGGED_OUT:
            Cookies.remove('lbcc_user');
            return null;
        case TOKEN_REFRESHED:
            const newState_11 = { ...state, ...action.payload };
            Cookies.set('lbcc_user', JSON.stringify(newState_11)), { secure: true, sameSite: 'none' };
            return newState_11;
        case PROFILE_UPDATED:
        case RING_MY_MOBILE_CHANGED:
            const user_details = state.user_details;
            const newState = { ...state, ...{ user_details: { ...user_details, ...action.payload } } };
            Cookies.set('lbcc_user', JSON.stringify(newState), { secure: true, sameSite: 'none' });
            return newState;
        case OUTBOUND_ID_CHANGED:
            const user_details_1 = state.user_details;
            const newState_1 = { ...state, ...{ user_details: { ...user_details_1, outboundservice: action.payload } } };
            Cookies.set('lbcc_user', JSON.stringify(newState_1), { secure: true, sameSite: 'none' });
            return newState_1;
        case USER_CONFIG_LOADED:
            state.user_config = action.payload
            return state;
        case GROUP_CONFIG_LOADED:
            state.group_config = action.payload
            return state;
        default:
            return state;
    }
}
