//export const BASE_URL2 = process.env.REACT_APP_BASE_API;
//export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = process.env.REACT_APP_BASE_URL;
export const FEEDBACK_API = process.env.REACT_APP_FEEDBACK_API;
export const NOTIFICATION_REFRESH_INTERVAL = process.env.REACT_APP_NOTIFICATION_REFRESH_INTERVAL || 5;
export const DASHBOARD_REFRESH_INTERVAL = process.env.REACT_APP_DASHBOARD_REFRESH_INTERVAL || 5;
export const RECORDINGS_URL = process.env.REACT_APP_RECORDINGS_URL;
export const RECORDINGS_URL_BACKUP = process.env.REACT_APP_RECORDINGS_URL_BACKUP;
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const LOCATION_TRACKER_LINK = process.env.REACT_APP_LOCATION_TRACKER_LINK;
// export const LOCAL_PHONE_REGEX = /^(?:0|94|\+94)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|912)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/; 
export const LOCAL_PHONE_REGEX = /^[0-9]{8,15}$/im;
export const INTL_PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const DASHBOARD_GRAPH_TYPE = "Outbound";
export const MI_API_URL = process.env.MI_API_URL;
export const LINK_3CX_CONFIG = process.env.REACT_APP_LINK_3CX_CONFIG || "https://simsyn.my3cx.sg";
export const LINK_3CX_REPORTS = process.env.REACT_APP_LINK_3CX_REPORTS || "https://simsyn.my3cx.sg/#/app/call_reports/call_reports";
export const HANDLING_TIME_SEC = process.env.REACT_APP_HANDLING_TIME_SEC || 5;
export const HANDLING_TIME_MAX = process.env.REACT_APP_HANDLING_TIME_MAX || 30;
export const PHONE_STATUS_REFRESH = process.env.REACT_APP_PHONE_STATUS_REFRESH || 3;
export const AGENT_STATUS_REFRESH = process.env.REACT_APP_AGENT_STATUS_REFRESH || 60;
export const AUTO_DIAL_INTERVAL = process.env.REACT_APP_AUTO_DIAL_INTERVAL || 15;
export const CAMPAIGN_REFRESH_INTERVAL = process.env.REACT_APP_CAMPAIGN_REFRESH_INTERVAL || 60;
export const AUTO_DIAL_ON_DISPOSITION = true;
export const MESSENGER_CLIENT = process.env.REACT_APP_MESSENGER_CLIENT;
export const MESSENGER_API_URL = process.env.REACT_APP_MESSENGER_API_URL;
export const LINK_CRM_CONFIG = process.env.REACT_APP_LINK_CRM_CONFIG || "https://ctc.crmconsole.callcenters.lk";
export const LINK_CRM_REPORTS = process.env.REACT_APP_LINK_CRM_REPORTS || "https://ctc.crmconsole.callcenters.lk";
export const DEFAULT_PAGE_SIZE = process.env.REACT_APP_DEFAULT_PAGE_SIZE || 10;
export const LOCAL_USER_MANAGEMENT = process.env.REACT_APP_LOCAL_USER_MANAGEMENT;
export const LEMD = (19854 + parseInt(process.env.REACT_APP_LICENSE_EXPIRY_DATE)) * 24 * 60 * 60 * 1000;

