import { DELIVERY_CITIES_LOADED } from '../config';

export default function (state = null, action) {
    switch (action.type) {
        case DELIVERY_CITIES_LOADED:
            return action.payload;
        default:
            return state;
    }
}
